import { defineComponent } from 'vue';
import { VueEditor } from "vue3-editor";
export default defineComponent({
    name: 'SuiteInput',
    components: {
        VueEditor: VueEditor
    },
    props: {
        type: {
            type: String,
            default: "text"
        },
        placeholder: {
            type: String,
            default: " "
        },
        required: {
            type: Boolean,
            default: false
        },
        minLength: {
            type: Number,
            default: 3
        },
        validation: {
            type: RegExp,
            default: undefined
        },
        label: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modelValue: [String, Number],
        withStyles: {
            type: Boolean,
            default: false
        },
        isEmail: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            errorMessage: "",
            error: false,
            customToolbar: [
                [{ 'font': [] }],
                [{ 'header': [false, 1, 2, 3, 4, 5, 6,] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                //[{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                //[{ 'header': 1 }, { 'header': 2 }],
                //['blockquote', 'code-block'],
                //[{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                //[{ 'script': 'sub'}, { 'script': 'super' }],
                //[{ 'indent': '-1'}, { 'indent': '+1' }],
                //[{ 'color': [] }, { 'background': [] }],
                //['link', 'formula'],
                //[{ 'direction': 'rtl' }],
                ['clean'],
            ]
        };
    },
    emits: ['update:modelValue', 'data-update'],
    watch: {
        value: function (newValue) {
            this.$emit('update:modelValue', newValue);
        }
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                this.$emit('update:modelValue', newValue);
            }
        }
    },
    methods: {
        hasErrors: function () {
            this.error = false; // Resetear el estado de error
            if (this.isRequiredError()) {
                this.error = true;
                this.errorMessage = "Este campo es obligatorio";
            }
            else if (!this.isValidEmail()) {
                this.error = true;
                // El mensaje de error ya debería estar configurado en `isValidEmail`
            }
            else if (!this.isValidFormat()) {
                this.error = true;
                // El mensaje de error ya debería estar configurado en `isValidFormat`
            }
            return this.error;
        },
        isEmpty: function () {
            return this.value === undefined || this.value === null || this.value === '';
        },
        isRequiredError: function () {
            return (this.required && this.isEmpty() ||
                (this.required && this.value !== undefined && this.value.toString().length < this.minLength));
        },
        isValidEmail: function () {
            if (this.isEmail) {
                var emailRegx = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
                if (!emailRegx.test(this.value.toString())) {
                    this.errorMessage = "Introduce un correo electrónico válido";
                    return false;
                }
            }
            return true;
        },
        isValidFormat: function () {
            // Si no hay validación definida o el valor es vacío, retorna verdadero
            if (!this.validation || this.value === undefined || this.value === null || this.value === '') {
                return true;
            }
            // Validación general basada en la expresión regular definida en 'validation'
            var isValid = this.value.toString().match(this.validation);
            if (!isValid) {
                this.errorMessage = "Introduce un formato correcto";
            }
            return isValid;
        }
    }
});
